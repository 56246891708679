<template>
    <b-modal v-model="exibe"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             size="lg"
             no-enforce-focus>
        <template v-slot:default="{ cancel, ok }">

            <div>
                <form id="modal-mensagens-email-form"
                      @submit.prevent="salvar(ok)">
                    <div class="container">

                        <div class="row pt-2">
                            <div class="col-8">
                                <label for="">Título:</label>
                                <input type="text" class="modal-mensagens-email-input" v-model="titulo">
                            </div>
                            <div class="col-4">
                                <label for="">Disponível:</label>
                                <select class="modal-mensagens-email-input" v-model="estaDisponivel">
                                    <option value="null">Selecione...</option>
                                    <option value="true">Sim</option>
                                    <option value="false">Não</option>
                                </select>
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div class="col-12">
                                <label for="">Mensagem:</label>
                                <HtmlEditor :dynamicArea="false" v-model="mensagemHtml" />
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div class="col-12">
                                <h6>Configurações de Envio</h6>
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div class="col-6">
                                <label> Habilitar automático: </label>
                                <select class="modal-mensagens-email-input" v-model="habilitarAutomatico">
                                    <option value="null">Selecione...</option>
                                    <option value="true">Sim</option>
                                    <option value="false">Não</option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label> Habilitar semi-automático: </label>
                                <select class="modal-mensagens-email-input" disabled v-model="habilitarSemiAutomatico">
                                    <option value="true">Sim</option>
                                    <option value="false">Não</option>
                                </select>
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div class="col-12">
                                <label>Termos para busca no email(separados por vírgula):</label>
                                <input type="text" class="modal-mensagens-email-input" v-model="termosDeBusca">
                            </div>
                        </div>

                        <div class="row pt-2">

                            <div class="col-6">
                                Selecione Setor:
                                <br>
                                <template v-for="(setor, i) in setoresDisponiveis">
                                    <input type="checkbox"
                                           :id="'input-setor' + setor.ID"
                                           :value="setor.ID"
                                           v-model="setores"
                                           :key="'key-setor' + setor.ID">
                                    <label :for="'input-setor' + setor.ID" :key="'label-setor' + setor.ID" class="ml-1"> {{ setor.Nome }} </label>
                                    <br :key="'br'+i">
                                </template>
                                <label v-if="!setoresDisponiveis || setoresDisponiveis.length == 0">Não existem setores disponíveis</label>
                                <br>
                            </div>

                            <div class="col-6">
                                Tipo de atendimento:
                                <br>
                                <input type="radio" id="tipo-atendimento-humano" value="Humano" v-model="tipoAtendimento">
                                <label for="tipo-atendimento-humano" class="ml-1">Humano</label>
                                <br>
                                <template v-if="tipoAtendimento == 'Humano'">
                                    <label for="">Email de redirecionamento:</label>
                                    <input type="text" class="modal-mensagens-email-input" v-model="emailRedirecionamento">
                                    <br>
                                </template>
                                <input type="radio" id="tipo-atendimento-sistema" value="Sistema" v-model="tipoAtendimento">
                                <label for="tipo-atendimento-sistema" class="ml-1">Sistema</label>
                                <br>
                            </div>
                        </div>

                        <div class="row py-2">
                            <div class="col-6">
                                <ul class="modal-mensagens-email-lista-erros">
                                    <li v-for="(erro, i) in erros" :key="i">{{ erro }}</li>
                                </ul>
                            </div>
                            <div class="col-6">
                                <botao-modal-ok class="float-right ml-2"
                                                type="submit">
                                    Confirmar
                                </botao-modal-ok>
                                <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
	import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
	import HtmlEditor from "@/components/inputs/HtmlEditor";

    export default {
        name: 'ModalMensagensEmail',

        components: {
            BotaoModalOk,
            BotaoModalCancel,
            HtmlEditor,
        },

        props: {
            setoresDisponiveis: {
                type: Array,
                default: null
            },
        },

        data: function () {
            return {
                exibe: false,
                erros: [],
                id: '',
                titulo: '',
                estaDisponivel: null,
                mensagemHtml: '',
                habilitarAutomatico: null,
                habilitarSemiAutomatico: false,
                termosDeBusca: '',
                setores: [],
                tipoAtendimento: '',
                emailRedirecionamento: '',
            }
        },

        methods: {
            show(msg) {
                this.limpa()
                this.exibe = true

                if (!msg) {
                    // Modo de criação, só abre o modal limpo
                    return
                }

                this.id = msg.Id
                this.titulo = msg.Nome
                this.estaDisponivel = msg.Disponivel
                this.mensagemHtml = msg.MensagenHtml
                this.habilitarAutomatico = msg.HabilitarAutomatico
                this.habilitarSemiAutomatico = msg.HabilitarSemiAutomatico
                this.termosDeBusca = msg.TermosBuscaEmail
                this.setores = msg.ModulosSetores.split(',')
                this.tipoAtendimento = msg.TransferirAtendente ? 'Humano' : 'Sistema'
                this.emailRedirecionamento = msg.EmailRedirecionamento
            },
            hide() {
                this.exibe = false
            },
            salvar(ok) {
                this.erros = []

                if (!this.titulo || this.titulo.length < 3) {
                    this.erros.push('Você deve inserir um nome com pelo menos três(3) caracteres!')
                }
                if (this.habilitarAutomatico == null) {
                    this.erros.push('Você deve selecionar sim ou não para Automático!')
                }
                if (this.habilitarAutomatico && this.termosDeBusca.length < 3) {
                    this.erros.push('Você deve inserir pelo menos 1 termo com mais de 3 caracteres!')
                }
                if (!this.setores || this.setores.length == 0) {
                    this.erros.push('Você deve selecionar ao menos 1 setor!')
                }

                if (this.erros.length > 0) {
                    return
                }

                this.mensagemHtml = this.$refs.editorEmail.getContent()

                const form = {
                    Id: this.id,
                    Nome: this.titulo,
                    Tipo: 'Email',
                    Disponivel: !!this.estaDisponivel,
                    MensagenHtml: this.mensagemHtml,
                    HabilitarAutomatico: !!this.habilitarAutomatico,
                    HabilitarBuscaAssunto: false,
                    HabilitarSemiAutomatico: !!this.habilitarSemiAutomatico,
                    TermosBuscaEmail: this.termosDeBusca,
                    Assunto: '',
                    ModulosSetores: this.setores.join(','),
                    EmailRedirecionamento: this.emailRedirecionamento,
                    TransferirAtendente: this.tipoAtendimento == 'Humano',
                }

                this.$emit('ok', form)
                // Metodo Ok do bootstrap que fecha o modal
                ok()
            },
            limpa() {
                this.erros = []
                this.id = ''
                this.titulo = ''
                this.estaDisponivel = null
                this.mensagemHtml = ''
                this.habilitarAutomatico = null
                this.habilitarSemiAutomatico = false
                this.termosDeBusca = ''
                this.setores = []
                this.tipoAtendimento = ''
                this.emailRedirecionamento = ''
            }
        }
    }
</script>

<style>
    .modal-mensagens-email-lista-erros {
        color: red;
    }

    .modal-mensagens-email-input {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }
</style>