<template>
    <div class="mensagens-email-page">
        <Breadcrumb titulo="MODULO EMAIL" :items="itensBreacrumb"/>
        <div class="container-fluid py-3">

            <div class="row">
                <div class="col-12">
                    <label class="mb-1"> Email SAC </label>
                </div>
            </div>
            <div class="row">
                <div class="col-3 pr-0">
                    <input type="text" class="mensagens-email-page-sac-input" v-model="emailSac">
                </div>
                <div class="col-3">
                    <botao-padrao @click="salvarSac" texto="Salvar" class="mensagens-email-page-botao-sac"/>
                </div>
            </div>

            <div class="row pt-3">
                <div class="col-12">
                    <tabela-mensagens-email 
                        :items="mensagensEmail"
                        :loading="mensagensEmailLoading"
                        @criar-mensagem="abrirModalCriacao"
                        @editar="abrirModalEdicao"
                        @remover="removerMsg"
                    />
                </div>
            </div>

        </div>

        <ModalMensagensEmail 
            ref="modal_mensagens_email"
            :setoresDisponiveis="setoresEmail"
            @ok="salvarMensagem"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import TabelaMensagensEmail from '@/components/tabelas/TabelaMensagensEmail.vue'
import ModalMensagensEmail from '@/components/modulo-email/ModalMensagensEmail.vue'
import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
import ModuloEmailServices from '@/assets/js/services/ModuloEmailServices'

export default {
    name: 'MensagensEmailPage',

    components: {
        Breadcrumb,
        TabelaMensagensEmail,
        ModalMensagensEmail,
        BotaoPadrao,
    },

    data: function () {
        return {
            emailSac: '',
            itensBreacrumb: [
                {
                    id: '1',
                    classe: 'fas fa-cogs',
                    texto: 'Cadastros Básicos',
                    link: '/cadastros-basicos'
                },
                {
                    id: '2',
                    classe: 'fa fa-envelope-o',
                    texto: 'Mensagens Email',
                    link: '/mensagens-email'
                },
            ],
        }
    },
    
    computed: {
        ...mapGetters({
            mensagensEmail: 'moduloEmail/mensagensEmail',
            mensagensEmailLoading: 'moduloEmail/mensagensEmailLoading',
            setoresEmail: 'moduloEmail/setoresEmail',
        }),
    },

    created() {
        this.buscaMensagensEmail()
        this.buscaSetoresEmail()
    },

    methods: {
        ...mapActions({
            setLoadingGlobal: 'setLoadingGlobal',
            buscaMensagensEmail: 'moduloEmail/buscaMensagens',
            buscaSetoresEmail: 'moduloEmail/buscaSetoresEmail',
            adicionarMensagemEmail: 'moduloEmail/adicionar',
            editarMensagemEmail: 'moduloEmail/editar',
            removerMensagemEmail: 'moduloEmail/remover',
            mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
            mostrarErro: 'mostradorDeMensagens/mostrarErro',
        }),
        abrirModalCriacao() {
            this.$refs.modal_mensagens_email.show()
        },
        abrirModalEdicao(id) {
            if(!id) {
                return
            }
            let msgEdicao = this.mensagensEmail.find(msg => msg.Id == id)
            if(!msgEdicao) {
                return
            }
            this.$refs.modal_mensagens_email.show(msgEdicao)
        },
        async salvarMensagem(msg) {
            this.setLoadingGlobal(true)
            try {
                if(!msg.Id) {
                    await this.adicionarMensagemEmail(msg)
                } else {
                    await this.editarMensagemEmail(msg)
                }
                this.$bvToast.toast('A mensagem foi salva.', {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                })
               
            } catch(e) {
                this.mostrarErro('Houve um erro ao tentar salvar a mensagem.')
                console.log('erro ao salvar mensagem', e)
            }
            this.setLoadingGlobal(false)
        },
        async removerMsg(id) {
            this.setLoadingGlobal(true)
            try {
                await this.removerMensagemEmail(id)
                this.$bvToast.toast('Remoção bem sucessida.', {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                })
                
            } catch (e) {
                console.log('erro ao remover mensagem', e)
                this.mostrarErro('Houve um erro na exclusão da mensagem.')
            }
            this.setLoadingGlobal(false)
        },
        async salvarSac() {
            this.setLoadingGlobal(true)
            try {
                await ModuloEmailServices.salvarSac(this.emailSac)
                this.$bvToast.toast('Email salvo com sucesso.', {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                })
               
            } catch (e) {
                console.log('erro salvarSac', e)
                this.mostrarErro('Houve um erro ao salvar email do Sac.')
            }
            this.setLoadingGlobal(false)
        },
        
    },
}
</script>

<style scoped>
.mensagens-email-page-sac-input {
    background-color: var(--cinza-2);
    color: var(--cinza-5);
    width: 100%;
    border: 1px solid var(--cinza-4);
    outline: none;
    height: 35px;
}

.mensagens-email-page-botao-sac {
    width: 150px;
}

</style>