<template>
    <b-modal 
        modal-class="modal-exportacao-tabelas"
        v-model="exibe" 
        body-class="app-modal-custom-body" 
        header-class="app-modal-custom-header" 
        footer-class="app-modal-custom-footer" 
        size="md"
        no-enforce-focus
    >
        <template v-slot:default="{ cancel, ok }">

            <div class="container-fluid h-100">
                
                <div class="row">
                    <div class="col-12">
                        <h3 class="modal-exportacao-tabelas-titulo text-center"> Exportar relatório </h3>
                    </div>
                </div>

                
                <div class="row pt-1">
                    <div class="col-12">
                        <h6 class="text-center">Selecione os campos desejados:</h6>
                    </div>
                </div>

                <div class="row py-3">
                    <div class="col-12">
                        <div class="modal-exportacao-tabelas-container-campos">
                            <div v-for="(campo, i) in camposDiponiveisParaExportacao" :key="i"  class="w-50">
                                <input 
                                    type="checkbox" 
                                    :id="'input-campos' + i" 
                                    :value="campo.key" 
                                    :key="'input' + i"
                                    v-model="camposSelecionadosParaExportacao"
                                >
                                <label :for="'input-campos' + i" class="ml-1"> {{ campo.label }} </label>
                                <br>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="row py-2">
                    <div class="col-12">
                        <botao-modal-ok 
                            class="float-right ml-2"
                            :disabled="!camposSelecionadosParaExportacao || camposSelecionadosParaExportacao.length == 0"
                            @click="exportar(ok)"
                        >
                            Exportar
                        </botao-modal-ok>
                        <botao-modal-cancel class="float-right ml-2" @click="cancel()" > Cancelar </botao-modal-cancel>
                    </div>
                </div>
                
            </div>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
                
            </div>
        </template>

    </b-modal>
</template>

<script>
import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';

export default {
    name: 'ModalExportacaoTabelas',

    components: {
        BotaoModalOk,
        BotaoModalCancel,
    },

    props: {
        camposDiponiveisParaExportacao: {
            type: Array,
            default: () => [],
        }
    },

    data: function() {
        return {
            exibe: false,
            camposSelecionadosParaExportacao: []
        }
    },

    computed: {
    },

        methods: {
            
        show() {
            this.exibe = true
                this.camposSelecionadosParaExportacao = this.camposDiponiveisParaExportacao.map(campo => campo.key)
        },
        hide() {
            this.limpa()
            this.exibe = false
        },
        limpa() {
        },
        
        exportar(ok) {
           
            const camposSelecionados = this.camposDiponiveisParaExportacao.filter(campo => this.camposSelecionadosParaExportacao.includes(campo.key))
            this.$emit('exportar', camposSelecionados)
            ok()
        },
    }
}
</script>

<style>
.modal-exportacao-tabelas-titulo {
    color: var(--cor-primaria-cliente);
}

.modal-exportacao-tabelas-container-campos {
    display: flex;
    flex-wrap: wrap;

}
</style>